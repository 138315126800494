import React, { useContext } from 'react'
import type { PropsWithChildren } from 'react'

import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { ContentVersionContext, ContentVersionContextValue } from 'components/contentVersion/ContentVersionProvider'
import {
  DropdownField,
  FileField,
  MediaField,
  EmbeddedField,
  MarkdownField,
  NumberField,
  ReferenceField,
  TextField,
  SwitchField
} from 'components/contentVersion/contentFields'
import { FieldIdentifier } from 'models/Field'
import { SECTION_GAP } from 'components/contentVersion/constants'
import { styled } from 'styles/stitches'
import type { Field as FieldType } from 'generated/schema'
import type { TemplateVersion } from 'models/Template'

type VersionDiffProps = {
  activeLocale: string,
  defaultLocale: string
} & (Pick<ContentVersionContextValue, 'fieldsList' | 'currentVersion' | 'previousVersion'> | {})

type Field = Pick<FieldType, 'fieldType' | 'identifier' | 'id' | 'name' | 'isTranslatable' | 'isArray'>

type ContentProps = PropsWithChildren<{
  field: Field,
  isEmpty?: boolean,
  isNested?: boolean,
  variant: 'positive' | 'negative' | 'neutral'
}>

type RenderFieldContentOptions = {
  activeLocale: string,
  defaultLocale: string
}

type ComputeDiffProps<T> = {
  currentValue?: T,
  previousValue?: T
}

type ComputeDiffOptions = {
  activeLocale: string,
  defaultLocale: string,
  field: Field
}

type ComputeDiffWithOptionsProps<T> = ComputeDiffProps<T> & {
  options: ComputeDiffOptions
}

const CONTENT_BEFORE_WIDTH = 6
const DIFF_WRAPPER_PADDING_Y = 30
const EMPTY_CONTENT_SIZE = 20

const StyledContent = styled(Flex, {
  flexBasis: `calc(50% - ${SECTION_GAP}px)`,
  flexGrow: 0,
  flexShrink: 0,
  height: 'fit-content',
  paddingLeft: 12,
  position: 'relative',

  '&::before': {
    content: "''",
    width: CONTENT_BEFORE_WIDTH,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%'
  },

  variants: {
    isNested: {
      true: {
        width: '100%'
      },
      false: {
        width: `calc(50% - ${SECTION_GAP}px)`
      }
    },
    variant: {
      neutral: {},
      positive: {
        '&::before': {
          backgroundColor: 'positive400'
        }
      },
      negative: {
        '&::before': {
          backgroundColor: 'negative400'
        }
      }
    }
  }
})

const StyledEmpty = styled(Flex, {
  size: [ EMPTY_CONTENT_SIZE ]
})

const StyledDiffWrapper = styled(Flex, {
  paddingY: DIFF_WRAPPER_PADDING_Y
})

function Content({ children, field, variant, isEmpty = false, isNested = false }: ContentProps) {
  if (isEmpty) {
    return null
  }

  return (
    <StyledContent direction="column" gap={6} variant={variant} isNested={isNested}>
      <Text fontSize={12} color="dark500">
        {field.name}
      </Text>
      {children}
    </StyledContent>
  )
}

const renderFieldContent = (
  currentSnapshot: any,
  previousSnapshot: any,
  field: Field,
  renderOptions: RenderFieldContentOptions
) => {
  const { activeLocale, defaultLocale } = renderOptions || {}
  const options = { field, activeLocale, defaultLocale }

  const locale = field.isTranslatable ? activeLocale : defaultLocale

  const { identifier, id = identifier } = field

  const currentData = currentSnapshot?.[id]
  const previousData = previousSnapshot?.[id]

  const currentValue = currentData?.[locale]
  const previousValue = previousData?.[locale]

  switch (field.fieldType as FieldIdentifier) {
    case FieldIdentifier.NUMBER:
      return NumberField.computeDiff({ previousValue, currentValue })
    case FieldIdentifier.SWITCH:
      return SwitchField.computeDiff({ previousValue, currentValue })
    case FieldIdentifier.DROPDOWN:
      return DropdownField.computeDiff({ previousValue, currentValue, options })
    case FieldIdentifier.MARKDOWN:
      return MarkdownField.computeDiff({ previousValue, currentValue })
    case FieldIdentifier.FILE:
      return FileField.computeDiff({ previousValue, currentValue })
    case FieldIdentifier.MEDIA:
      return MediaField.computeDiff({ previousValue, currentValue })
    case FieldIdentifier.EMBEDDED:
      return EmbeddedField.computeDiff({ previousValue, currentValue, options })
    case FieldIdentifier.REFERENCE:
      return ReferenceField.computeDiff({ previousValue, currentValue, options })
    case FieldIdentifier.TEXT:
    default:
      return TextField.computeDiff({ previousValue, currentValue })
  }
}

function VersionDiff({ activeLocale, defaultLocale, ...props }: VersionDiffProps) {
  const context = useContext(ContentVersionContext)
  const {
    fieldsList,
    currentVersion,
    previousVersion
  } = {
    ...context,
    ...props
  }

  const currentContentSnapshot = currentVersion?.snapshot
    || (currentVersion as unknown as TemplateVersion)?.settings
  const previousContentSnapshot = previousVersion?.snapshot
    || (previousVersion as unknown as TemplateVersion)?.settings

  return (
    <StyledDiffWrapper direction="column" gap={20}>
      {fieldsList.map((field) => {
        const { identifier, id = identifier } = field

        const {
          currentNode,
          previousNode,
          isDiff
        } = renderFieldContent(
          currentContentSnapshot,
          // If 1st version is inspected then previousContentSnapshot would be empty
          // So we are comparing the current with itself which would result to "no change"
          previousContentSnapshot || currentContentSnapshot,
          field,
          { activeLocale, defaultLocale }
        )

        return (
          <Flex key={id}>
            <Content
              field={field}
              variant={isDiff ? 'positive' : 'neutral'}
            >
              {currentNode || <StyledEmpty />}
            </Content>
            <Divider variant="whitespace" spacing={32} orientation="vertical" />
            <Content
              field={field}
              isEmpty={!previousVersion}
              variant={isDiff ? 'negative' : 'neutral'}
            >
              {previousNode || <StyledEmpty />}
            </Content>
          </Flex>
        )
      })}
    </StyledDiffWrapper>
  )
}

export {
  Content,
  renderFieldContent,
  StyledEmpty
}

export type {
  ComputeDiffProps,
  ComputeDiffWithOptionsProps
}

export default VersionDiff
