import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useContext } from 'react'
import type { ValueType } from 'react-select'

import AccountAvatar from 'components/avatars/AccountAvatar'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Label from 'components/typography/Label'
import Select from 'components/select/Select'
import Text from 'components/typography/Text'
import { ContentVersion, ContentVersionStatus } from 'models/Content'
import { ContentVersionContext, ContentVersionContextValue } from 'components/contentVersion/ContentVersionProvider'
import { SECTION_GAP } from 'components/contentVersion/constants'
import { SMALL_HORIZONTAL_PADDING } from 'components/modal/constants'
import { styled } from 'styles/stitches'
import type { SelectOptionType } from 'components/select/Select'
import type { TemplateVersion } from 'models/Template'

dayjs.extend(relativeTime)

type VersionOptionType = {
  label: string,
  value: string
}

type SectionHeaderProps = {
  label: string,
  onChange: (versionId: string) => void,
  options: VersionOptionType[],
  version?: ContentVersion | null
}

const SECTION_HEADER_HEIGHT = 80
const PUBLISHED_AT_WRAPPER_WIDTH = 100
const SELECT_WRAPPER_WIDTH = 300

const StyledWrapper = styled(Flex, {
  backgroundColor: 'light100',
  height: SECTION_HEADER_HEIGHT,
  marginX: -1 * SMALL_HORIZONTAL_PADDING,
  paddingX: SMALL_HORIZONTAL_PADDING
})

const StyledSectionHeader = styled(Flex, {
  flex: 1,
  flexBasis: 0
})

const StyledSelectWrapper = styled(Flex, {
  width: SELECT_WRAPPER_WIDTH
})

const StyledPublishedAtWrapper = styled(Flex, {
  width: PUBLISHED_AT_WRAPPER_WIDTH,
  textAlign: 'right'
})

const SectionHeader = ({
  label,
  onChange,
  options,
  version
}: SectionHeaderProps) => {
  const selectedOptionIndex = options.findIndex((o) => o.value === version?.id)
  const handleVersionChange = (option: ValueType<SelectOptionType>) => {
    const { value } = option as VersionOptionType
    onChange(value)
  }

  if (!version) {
    return null
  }

  const isPublished = version.status === ContentVersionStatus.PUBLISHED

  return (
    <StyledSectionHeader alignItems="center" justifyContent="space-between">
      {version && (
        <>
          <StyledSelectWrapper alignItems="center" gap={20}>
            <Label fontWeight="bold">{label}</Label>
            <Select
              isSearchable={false}
              options={options}
              value={options[selectedOptionIndex]}
              onChange={handleVersionChange}
              size="small"
            />
          </StyledSelectWrapper>
          <Flex css={{ height: 40 }} alignItems="center" grow={1} gap={20} justifyContent="flex-end">
            <AccountAvatar variant="small" account={version.createdBy || (version as unknown as TemplateVersion).author} />
            <StyledPublishedAtWrapper direction="column">
              <Text fontSize={10} textTransform="uppercase">{isPublished ? 'Published' : 'Saved'}</Text>
              <Text fontSize={12} fontWeight="bold">
                {dayjs().to(dayjs(version.createdAt))}
              </Text>
            </StyledPublishedAtWrapper>
          </Flex>
        </>
      )}
    </StyledSectionHeader>
  )
}

type VersionHeaderProps = Pick<ContentVersionContextValue, 'versions' | 'currentVersion' | 'previousVersion' | 'setCurrentVersionId' | 'setPreviousVersionId'> | {}

function VersionHeader(props: VersionHeaderProps) {
  const context = useContext(ContentVersionContext)

  const {
    versions,
    currentVersion,
    previousVersion,
    setCurrentVersionId,
    setPreviousVersionId
  } = { ...context, ...props }

  const options = versions.map(({ name, id }) => ({
    label: name, value: id
  }))

  const onCurrentVersionChange = (currentVersionId: string) => {
    const selectedVersionIndex = versions.findIndex((v) => v.id === currentVersionId)

    const nextVersionIndex = selectedVersionIndex + 1
    if (nextVersionIndex === versions.length) {
      setPreviousVersionId(undefined)
    } else {
      setPreviousVersionId(versions[nextVersionIndex].id)
    }
    setCurrentVersionId(currentVersionId)
  }

  return (
    <StyledWrapper grow={1} gap={SECTION_GAP}>
      <SectionHeader
        label="Inspect"
        onChange={onCurrentVersionChange}
        options={options}
        version={currentVersion}
      />
      <Divider orientation="vertical" />
      <SectionHeader
        label="Compare"
        onChange={setPreviousVersionId}
        options={options}
        version={previousVersion}
      />
    </StyledWrapper>
  )
}

export default VersionHeader
