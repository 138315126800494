import React, { useContext } from 'react'
import { useRecoilValue } from 'recoil'

import DashboardContext from 'components/contexts/DashboardContext'
import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { Views } from './constants'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { View } from 'generated/schema'
import type { ActiveViewProps } from './DashboardEditor'

const primaryActions = [
  {
    title: 'Add Block',
    identifier: Views.ADD_BLOCK,
    media: 'block'
  },
  {
    title: 'Add Menu',
    identifier: Views.ADD_MENU,
    media: 'menu'
  },
  {
    title: 'Edit Component',
    identifier: Views.EDIT_COMPONENT,
    media: 'inspect'
  }
] as const

const workspaceActions = [
  {
    title: 'Projects',
    identifier: Views.PROJECT,
    media: 'projects'
  },
  {
    title: 'Apps',
    identifier: Views.APP,
    media: 'apps'
  },
  {
    title: 'Integrations',
    identifier: Views.INTEGRATIONS,
    media: 'integration'
  },
  {
    title: 'Schema',
    identifier: Views.SCHEMA,
    media: 'graph'
  }
] as const

type ActionsGridProps = {
  actions: typeof primaryActions | typeof workspaceActions,
  title: string
}

function ActionsView({ onClose }: ActiveViewProps) {
  return (
    <>
      <DashboardEditorHeader
        heading="Dashboard Editor"
        onClose={onClose}
      />
      <DashboardEditorBody>
        <ActionsGrid title="Actions" actions={primaryActions} />
        <Related />
        <ActionsGrid title="Workspace" actions={workspaceActions} />
      </DashboardEditorBody>
    </>
  )
}

const ActionsGrid = ({ actions, title }: ActionsGridProps) => {
  const { openDashboardEditorView } = useDashboard()

  return (
    <Flex gap={16} direction="column">
      <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">{title}</Text>
      <Flex direction="column" gap={2}>
        {actions.map((action) => {
          const onClick = () => openDashboardEditorView({ target: action.identifier })
          return (
            <MediaCard
              compact
              key={action.identifier}
              title={action.title}
              media={action.media}
              height={64}
              width="full"
              onClick={onClick}
              actions={[ {
                description: '',
                icon: 'arrow-right',
                onClick,
                isIconAlwaysVisible: true
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

const getRelatedCards = (
  view: Omit<View, 'blocks' | 'operations'>,
  menuElement?: ComputedMenuElement
) => {
  switch (view.kind) {
    case 'CUSTOM':

      return [
        {
          title: view.name,
          text: `${view.kind} VIEW`,
          media: 'dashboard',
          params: {
            initialValues: view
          },
          identifier: Views.VIEW_DETAILS
        }
      ] as const
    case 'APP': {
      const viewCard = {
        title: view.name,
        text: `${view.kind} VIEW`,
        media: view.app?.icon || 'apps',
        params: {
          initialValues: view
        },
        identifier: Views.VIEW_DETAILS
      }

      return [
        {
          title: view.app?.name || '',
          text: 'APP',
          media: view.app?.icon || 'apps',
          params: {
            app: view.app,
            parentView: Views.ACTIONS
          },
          identifier: Views.APP_DETAILS
        },
        viewCard
      ] as const
    }
    case 'RESOURCE': {
      const projectCard = view.resource?.appId ? [ {
        title: view.resource?.app?.name || '',
        text: 'PROJECT',
        media: view.resource?.app?.icon || 'apps',
        params: {
          app: view.resource?.app,
          parentView: Views.ACTIONS
        },
        identifier: Views.PROJECT_DETAILS
      } ] as const : []

      const viewCard = {
        title: view.name || view.resource?.name || menuElement?.renderedName || '',
        text: `${view.kind} VIEW`,
        media: 'graph',
        params: {
          initialValues: view
        },
        identifier: Views.VIEW_DETAILS
      }

      const resourceCard = view.resource ? [ {
        title: view.resource?.name || '',
        text: 'RESOURCE',
        media: view.resource?.icon || 'graph',
        params: {
          resource: view.resource,
          parentView: Views.ACTIONS
        },
        identifier: Views.RESOURCE_DETAILS
      } ] as const : []

      return [
        ...resourceCard,
        ...projectCard,
        viewCard
      ] as const
    }
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown view kind: ${view.kind}`)
      return []
  }
}

const Related = () => {
  const { openDashboardEditorView, viewState } = useDashboard()
  const { urn } = useDashboardEditorContextProvider()
  const activeView = useRecoilValue(viewState(urn))

  const {
    selectedSideMenuElement,
    selectedTopMenuElement
  } = useContext(DashboardContext)!
  const selectedMenuElement = selectedSideMenuElement || selectedTopMenuElement

  const mediaCards = getRelatedCards(
    activeView,
    selectedMenuElement
  )

  if (!activeView) {
    return null
  }

  return (
    <Flex gap={16} direction="column">
      <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">Related</Text>
      <Flex direction="column" gap={2}>
        {mediaCards.map((card) => {
          const { identifier, title, text, media, params } = card
          const onClick = () => openDashboardEditorView({
            target: identifier,
            params
          })
          return (
            <MediaCard
              key={identifier}
              title={title}
              titlePosition="top"
              text={text}
              media={media}
              width="full"
              onClick={onClick}
              actions={[ {
                description: '',
                icon: 'arrow-right',
                onClick,
                isIconAlwaysVisible: true
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default ActionsView
