/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone'
import { defaultDataIdFromObject } from '@apollo/client'
import { FileRejection, useDropzone } from 'react-dropzone'
import { read, utils } from 'xlsx'
import { useField } from 'react-final-form'
import type Player from 'video.js/dist/types/player'
// @ts-ignore
import { Record } from 'videojs-record'

import * as mixins from 'styles/mixins'
import Button from 'components/buttons/Button'
import Dialog from 'components/dialog/Dialog'
import DialogBody from 'components/dialog/DialogBody'
import DialogFooter from 'components/dialog/DialogFooter'
import DialogHeader from 'components/dialog/DialogHeader'
import Divider from 'components/divider/Divider'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import { AssetFragmentFragment as AssetFragment, AssetFragmentFragmentDoc } from 'generated/schema'
import { cache } from 'client'
import { colorVars } from 'styles/theme'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { MEDIA_TYPE_OPTIONS, MEDIA_TYPE_TO_MIME_MAPPING, settings } from 'components/contentEditors/generic/fields/fieldProps'
import { Preview } from 'components/views/graph/ImportRecordsDialog'
import { styled } from 'styles/stitches'
import type { ModalProps } from 'components/modal/Modal'

import soundWave from 'assets/images/sound-wave-colored.svg'

// @ts-ignore
// eslint-disable-next-line
import videojs from '!video.js'

import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'

if (videojs.getPlugin('record') === undefined) {
  videojs.registerPlugin('record', Record)
}

type FileUploadDialogProps = {
  isOpen: ModalProps['isOpen'],
  isArray?: boolean,
  onClose: () => void,
  onSubmit: (image: (File | AssetFragment)[]) => Promise<any>,
  title: string,
  fileTypeCategory: settings['media']['fileTypeCategory'],
  accept?: string[],
  includeExtensions?: string[],
  name: string,
  view?: FileUploadDialogView,
  index?: number
}

const DIALOG_WIDTH = 800
const DIALOG_BODY_HEIGHT = 514

const StyledFileInput = styled(Flex, {
  ...mixins.transition('simple'),

  borderColor: 'dark100',
  borderRadius: 6,
  overflow: 'hidden',
  position: 'relative',
  height: DIALOG_BODY_HEIGHT - DIALOG_PADDING * 2,
  width: '100%',

  '&:hover': {
    borderColor: 'dark200'
  },

  variants: {
    variant: {
      bordered: {
        borderStyle: 'dashed',
        borderWidth: 2
      },
      bare: {}
    }
  }
})

const importOptions = {
  [MEDIA_TYPE_OPTIONS.IMAGE]: [
    {
      label: 'Upload',
      icon: 'import'
    },
    {
      label: 'Camera',
      icon: 'camera-alt'
    }
  ],
  [MEDIA_TYPE_OPTIONS.VIDEO]: [
    {
      label: 'Upload',
      icon: 'import'
    },
    {
      label: 'Camera',
      icon: 'camera-alt'
    }
  ],
  [MEDIA_TYPE_OPTIONS.AUDIO]: [
    {
      label: 'Upload',
      icon: 'import'
    },
    {
      label: 'Record',
      icon: 'microphone'
    }
  ],
  [MEDIA_TYPE_OPTIONS.SPREADSHEET]: [
    {
      label: 'Upload',
      icon: 'import'
    },
    {
      label: 'Library',
      icon: 'library'
    },
    {
      label: 'URL',
      icon: 'link'
    }
  ],
  [MEDIA_TYPE_OPTIONS.DOCUMENT]: [
    {
      label: 'Upload',
      icon: 'import'
    },
    {
      label: 'Library',
      icon: 'library'
    },
    {
      label: 'URL',
      icon: 'link'
    }
  ],
  [MEDIA_TYPE_OPTIONS.PRESENTATION]: [
    {
      label: 'Upload',
      icon: 'import'
    },
    {
      label: 'Library',
      icon: 'library'
    },
    {
      label: 'URL',
      icon: 'link'
    }
  ],
  [MEDIA_TYPE_OPTIONS.CUSTOM]: [
    {
      label: 'Upload',
      icon: 'import'
    }
  ]
}

const MediaButton = styled('button', {
  ...mixins.transition('simple'),

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 10,
  width: 100,
  height: 100,
  borderRadius: 4,
  border: '2px solid dark100',
  color: 'dark500',
  cursor: 'pointer',

  '&:hover': {
    borderColor: 'dark200',
    color: 'dark700'
  },

  '&:focus': {
    borderColor: 'dark200',
    color: 'dark700'
  }
})

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
let context: AudioContext
let processor: ScriptProcessorNode
if (isSafari) {
  // Safari 11 or newer automatically suspends new AudioContext's that aren't
  // created in response to a user-gesture, like a click or tap, so create one
  const AudioContext = window.AudioContext || (window as any).webkitAudioContext
  context = new AudioContext()
  processor = context.createScriptProcessor(1024, 1, 1)
}

const WavePreview = ({ url = '', onSubmit }: any) => {
  const wavesurferRef = useRef<WaveSurfer | null>(null)
  const mediaBlobsRef = useRef<Blob[]>()
  const [ containerEl, setContainerEl ] = useState<HTMLDivElement | null>(null)
  const [ isPlaying, setIsPlaying ] = useState(false)
  const [ isRecording, setIsRecording ] = useState(false)

  useEffect(() => {
    if (!containerEl) return () => wavesurferRef.current?.destroy()

    wavesurferRef.current = wavesurferRef.current
      || WaveSurfer.create({
        height: 250,
        container: containerEl,
        waveColor: 'rgba(255, 255, 255, 0.7)',
        progressColor: 'rgba(255, 255, 255, 0.9)',
        scrollParent: true,
        cursorColor: 'white',
        backgroundColor: '#3a4165',
        audioContext: context,
        audioScriptProcessor: processor,
        plugins: [
          MicrophonePlugin.create({
            bufferSize: 4096,
            numberOfInputChannels: 1,
            numberOfOutputChannels: 1,
            constraints: {
              video: false,
              audio: true
            }
          })
        ]
      })

    if (url) {
      const load = () => wavesurferRef.current?.load(url)
      wavesurferRef.current.on('backend-created', load)
      if (wavesurferRef.current.backend) {
        load()
      }
    }

    return () => wavesurferRef.current?.destroy()
  }, [ containerEl, url ])

  useEffect(() => {
    const wavesurfer = wavesurferRef.current
    if (!wavesurfer) return undefined

    const handleStream = (stream: MediaStream) => {
      const rec = new MediaRecorder(stream)
      rec.addEventListener('dataavailable', (event) => {
        mediaBlobsRef.current = [ event?.data ]
        onSubmit(new Blob(mediaBlobsRef.current, { type: rec.mimeType }))
      })
      rec.start()
    }

    wavesurfer.microphone.on('deviceReady', handleStream)

    return () => {
      wavesurfer.microphone.un('deviceReady', handleStream)
    }
  }, [ onSubmit, containerEl, url ])

  const handlePlayPause = () => {
    if (!wavesurferRef.current) return
    wavesurferRef.current.playPause()
    setIsPlaying(wavesurferRef.current.isPlaying())
  }

  const handleRecord = () => {
    if (!wavesurferRef.current) return
    if (isRecording) {
      wavesurferRef.current.microphone.stop()
      setIsRecording(false)
    } else {
      wavesurferRef.current.microphone.start()
      setIsRecording(true)
    }
  }

  return (
    <Flex
      grow={1}
      alignItems="center"
      css={{
        marginX: -DIALOG_PADDING,
        '& *': {
          '-ms-overflow-style': 'none', /* Internet Explorer 10+ */
          'scrollbar-width': 'none' /* Firefox */
        },
        '& *::-webkit-scrollbar': {
          display: 'none' /* Safari and Chrome */
        }
      } as any}
    >
      <div ref={setContainerEl} style={{ flexGrow: 1 }} />
      <Flex css={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: 8,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 'above'
      } as any}
      >
        {onSubmit
          ? isRecording
            ? <Icon color="light100" name="unchecked-box" size={48} onClick={handleRecord} />
            : <Icon color="light100" name="microphone" size={48} onClick={handleRecord} />
          : isPlaying
            ? <Icon color="light100" name="pause-circle" size={48} onClick={handlePlayPause} />
            : <Icon color="light100" name="play-circle" size={48} onClick={handlePlayPause} />}
      </Flex>
    </Flex>
  )
}

const VideoPreview = ({ options = {}, style, onSubmit }: any) => {
  const videoRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef<Player>()

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js')

      videoElement.classList.add('vjs-big-play-centered')
      videoRef.current?.appendChild(videoElement)

      playerRef.current = videojs(videoElement, options)
    } else {
      playerRef.current.options(options)
    }
  }, [ options ])

  useEffect(() => {
    const player = playerRef.current
    if (!player) return undefined
    // @ts-ignore
    window.player = player

    const handleSubmit = () => {
      // @ts-ignore
      onSubmit(player.recordedData)
    }

    player.on('finishRecord', handleSubmit)

    return () => {
      player.off('finishRecord', handleSubmit)
    }
  }, [ onSubmit ])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = undefined
      }
    }
  }, [ playerRef ])

  return (
    <div style={style} data-vjs-player>
      <div ref={videoRef} />
    </div>
  )
}

const AddMoreButton = styled('button', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 12,
  border: '2px dashed dark100',
  height: 190,
  borderRadius: 6,
  cursor: 'pointer',
  '&:hover': {
    borderColor: 'dark200'
  }
})

const SpreadsheetPreview = ({ url, backBtn }: any) => {
  const [ fileRejectionError, setFileRejectionError ] = useState<string | null>(null)
  const [ data, setData ] = useState<any[]>([])
  const [ headers, setHeaders ] = useState<any[]>([])

  useEffect(() => {
    if (!url) return

    (async () => {
      try {
        const file = await fetch(url).then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.blob()
        }).then((blob) => (new File([ blob ], url.split('/').pop() || '')))

        // Skip preview when file is greater than 20mb
        if (file.size > 20_000_000) {
          setFileRejectionError('File size is too large')
          return
        }

        const ab = await file.arrayBuffer()

        /* parse */
        const wb = read(ab)

        /* generate array of data from the first worksheet */
        const ws = wb.Sheets[wb.SheetNames[0]] // get the first worksheet
        const data = utils.sheet_to_json(ws) // generate objects

        const heads = Object.keys((data[0] || {}) as any)
        setHeaders(heads)
        // Only preview first 500 rows
        setData(data.map((d) => Object.values(d as any)).slice(0, 500))
      } catch {
        setFileRejectionError('Invalid file')
      }
    })()
  }, [ url ])

  return (
    <Flex
      css={{
        height: DIALOG_BODY_HEIGHT,
        margin: -DIALOG_PADDING,
        position: 'relative' as const
      }}
    >
      {!fileRejectionError && data.length ? (
        <Preview
          data={data}
          headers={headers}
          disableValidationUI
          readOnly
        />
      ) : (
        <Flex alignItems="center" justifyContent="center" alignSelf="stretch" grow={1}>
          {fileRejectionError
            ? (
              <Flex direction="column" alignItems="center">
                <Text fontSize="18" fontWeight="semibold">No Preview</Text>
                <Text color="dark500" fontSize="14">{fileRejectionError}</Text>
              </Flex>
            )
            : <Text>Loading...</Text>}
        </Flex>
      )}
      {backBtn}
    </Flex>
  )
}

type FileUploadDialogView = 'upload' | 'record' | 'preview' | 'list' | 'paste'

function FileUploadDialogWrapped({
  onClose,
  onSubmit,
  title,
  fileTypeCategory,
  accept,
  includeExtensions,
  isArray,
  name,
  view: initialView = 'upload',
  index: activeIndex = 0
}: FileUploadDialogProps) {
  const [ fileRejectionError, setFileRejectionError ] = useState<string | null>(null)
  const [ view, setView ] = useState<FileUploadDialogView>(initialView)
  const [ previewIndex, setPreviewIndex ] = useState(activeIndex)
  const [ uploadIndex, setUploadIndex ] = useState(activeIndex)

  const field = useField(name)

  const parseValues = () => (
    Array.isArray(field.input.value)
      ? field.input.value
      : [ field.input.value ]
  ).map((file) => cache.readFragment<AssetFragment>({
    id: defaultDataIdFromObject({
      __typename: 'Asset',
      id: file.id || file
    }),
    fragment: AssetFragmentFragmentDoc,
    fragmentName: 'AssetFragment'
  })!).filter(Boolean)

  const [ files, setFiles ] = useState<(File | AssetFragment)[]>(parseValues)

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles.length > 0) {
      const newFiles = [
        ...files.slice(0, uploadIndex),
        ...acceptedFiles,
        ...files.slice(uploadIndex + 1)
      ]
      setFiles(newFiles)
      setUploadIndex(newFiles.length)
      setFileRejectionError(null)
      setView(isArray ? 'list' : 'preview')
    } else {
      setFiles([])
      setFileRejectionError(fileRejections[0].errors[0].message)
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: accept || (MEDIA_TYPE_TO_MIME_MAPPING[fileTypeCategory] ?? includeExtensions),
    multiple: isArray,
    noClick: true
  })

  const mediaSrc = useMemo(() => {
    try {
      return files.map((file) => (file as AssetFragment).url || URL.createObjectURL(file))
    } catch (e) {
      return []
    }
  }, [ files ])

  const { css, ...rootProps } = getRootProps()

  const handleMediaButtonClick = (identifier: string) => {
    switch (identifier) {
      case 'import':
        open()
        break
      case 'microphone':
        setView('record')
        break
      case 'camera-alt':
        setView('record')
        break
      case 'library':
        break
      case 'link':
        setView('paste')
        break
    }
  }

  const renderback = (to: FileUploadDialogView, label: String, variant: 'light' | 'dark') => (
    <Flex
      as="button"
      type="button"
      alignItems="center"
      gap={4}
      css={{
        ...mixins.dropShadow('icon', colorVars.dark500rgb),
        alignSelf: 'flex-start',
        zIndex: 'above',
        color: variant === 'light' ? 'light100' : 'dark500',
        position: 'absolute'
      } as any}
      onClick={() => {
        setView(to)
        setUploadIndex(files.length)
      }}
    >
      <Icon size={16} name="arrow-left" />
      <Text
        color={variant === 'light' ? 'light100' : 'dark500'}
        fontSize="10"
        textTransform="uppercase"
        shrink={0}
      >
        {label}
      </Text>
    </Flex>
  )

  const uploadView = (
    <StyledFileInput
      {...rootProps}
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={48}
      variant={files.length ? 'bare' : 'bordered'}
    >
      {!!files.length && (
        <div style={{ position: 'absolute', left: 0, top: 0 }}>
          {renderback(
            isArray ? 'list' : 'preview',
            'Back to preview',
            'dark'
          )}
        </div>
      )}
      <Text>Drag-and-drop file(s), <TextLink onClick={open}>browse</TextLink> or choose from:</Text>
      <Flex gap={8}>
        {importOptions[fileTypeCategory || MEDIA_TYPE_OPTIONS.CUSTOM].map(({ label, icon }) => (
          <MediaButton as="button" key={label} onClick={() => handleMediaButtonClick(icon)}>
            <Icon
              size={32}
              name={icon}
            />
            <Text css={{ color: 'inherit' }}>{label}</Text>
          </MediaButton>
        ))}
      </Flex>
      <input {...getInputProps()} />
    </StyledFileInput>
  )

  const getImagePreview = () => (
    <Flex
      css={{
        height: DIALOG_BODY_HEIGHT,
        backgroundColor: 'dark500',
        margin: -DIALOG_PADDING,
        padding: DIALOG_PADDING,
        position: 'relative' as const
      }}
    >
      <img
        key={mediaSrc[previewIndex]}
        alt="preview"
        src={mediaSrc[previewIndex]}
        style={{
          position: 'absolute',
          inset: 0,
          height: '100%',
          width: '100%',
          objectFit: 'scale-down'
        }}
      />
      {isArray && renderback(
        'list',
        'Back to preview',
        'light'
      )}
    </Flex>
  )

  const getAudioPreview = () => (
    <Flex
      css={{
        height: DIALOG_BODY_HEIGHT,
        margin: -DIALOG_PADDING,
        padding: DIALOG_PADDING,
        position: 'relative' as const
      }}
    >
      {(files[previewIndex] as AssetFragment)?.data?.storage_provider === 'mux'
        ? (
          <VideoPreview
            options={{
              sources: [
                {
                  src: mediaSrc[previewIndex],
                  type: (files[previewIndex] as AssetFragment)?.mimeType
              || (files[previewIndex] as File)?.type
                },
                {
                  src: mediaSrc[previewIndex]
                }
              ],
              poster: soundWave,
              height: DIALOG_BODY_HEIGHT,
              width: DIALOG_WIDTH,
              audioPosterMode: true,
              controls: true,
              autoplay: true
            }}
            key={mediaSrc[previewIndex]}
            style={{
              position: 'absolute',
              inset: 0,
              height: '100%',
              width: '100%'
            }}
          />
        )
        : <WavePreview key={mediaSrc[previewIndex]} url={mediaSrc[previewIndex]} />}
      {isArray && renderback(
        'list',
        'Back to preview',
        'dark'
      )}
    </Flex>
  )

  const getVideoPreview = () => (
    <Flex
      css={{
        height: DIALOG_BODY_HEIGHT,
        margin: -DIALOG_PADDING,
        padding: DIALOG_PADDING,
        position: 'relative' as const,
        backgroundColor: 'dark500'
      }}
    >
      <VideoPreview
        options={{
          sources: [
            {
              src: mediaSrc[previewIndex],
              type: (files[previewIndex] as AssetFragment)?.mimeType
                || (files[previewIndex] as File)?.type
            },
            {
              src: mediaSrc[previewIndex]
            }
          ],
          height: DIALOG_BODY_HEIGHT,
          width: DIALOG_WIDTH,
          controls: true,
          autoplay: true
        }}
        key={mediaSrc[previewIndex]}
        style={{
          position: 'absolute',
          inset: 0,
          height: '100%',
          width: '100%'
        }}
      />
      {isArray && renderback(
        'list',
        'Back to preview',
        'light'
      )}
    </Flex>
  )

  const getSpreadsheetPreview = () => (
    <SpreadsheetPreview
      url={mediaSrc[previewIndex]}
      backBtn={isArray && renderback(
        'list',
        'Back to preview',
        'light'
      )}
    />
  )

  const preview = fileTypeCategory === MEDIA_TYPE_OPTIONS.IMAGE
    ? getImagePreview()
    : fileTypeCategory === MEDIA_TYPE_OPTIONS.AUDIO
      ? getAudioPreview()
      : fileTypeCategory === MEDIA_TYPE_OPTIONS.VIDEO
        ? getVideoPreview()
        : fileTypeCategory === MEDIA_TYPE_OPTIONS.SPREADSHEET
          ? getSpreadsheetPreview()
          : uploadView

  const listView = (
    <Grid css={{ minHeight: DIALOG_BODY_HEIGHT - DIALOG_PADDING }} gap={16} columns={3}>
      <AddMoreButton
        type="button"
        onClick={() => {
          setUploadIndex(files.length)
          setView('upload')
        }}
      >
        <Icon name="add-outline-round" color="dark500" />
        <Text color="dark500" fontSize="12">Add more</Text>
      </AddMoreButton>
      {mediaSrc.map((src, index) => (
        <Flex
          direction="column"
          gap={8}
          key={src}
          css={{
            position: 'relative' as const,
            '[data-actions]': {
              ...mixins.transition('simple'),
              opacity: 0
            },
            '&:hover [data-actions]': {
              opacity: 1
            }
          }}
        >
          <Flex
            data-actions
            css={{
              ...mixins.dropShadow('icon', colorVars.dark700rgb, 0.3),
              height: 32,
              position: 'absolute' as const,
              top: 0,
              right: 0,
              backgroundColor: 'dark900',
              padding: 4,
              borderRadius: 6
            }}
          >
            <IconButton
              onClick={() => {
                setView('upload')
                setUploadIndex(index)
              }}
              size={12}
              description="Edit"
              variant="light"
              name="edit"
            />
            <IconButton
              onClick={() => {
                setFiles(files.filter((_, i) => i !== index))
                setUploadIndex(files.length - 1)
              }}
              size={12}
              description="Delete"
              variant="light"
              name="trash"
            />
          </Flex>
          <Flex
            as="button"
            type="button"
            alignItems="stretch"
            css={{
              ...mixins.shadow('xxSmall', colorVars.dark600rgb),
              backgroundColor: 'dark500',
              height: 190,
              width: '100%',
              borderRadius: 6
            }}
            onClick={() => {
              setView('preview')
              setPreviewIndex(index)
            }}
          >
            {fileTypeCategory === MEDIA_TYPE_OPTIONS.VIDEO
              ? (
                <video
                  style={{ objectFit: 'scale-down', width: '100%' }}
                  src={src}
                  // poster={(files[index] as AssetFragment)?.thumbnailUrl}
                />
              ) : (
                <img
                  style={{ objectFit: 'scale-down', width: '100%' }}
                  src={fileTypeCategory === MEDIA_TYPE_OPTIONS.AUDIO ? soundWave : src}
                  alt="thumbnail"
                />
              )}
          </Flex>
          <Text fontSize={12} truncate>
            {files[index].name}
          </Text>
        </Flex>

      ))}
    </Grid>
  )

  const recordView = fileTypeCategory === MEDIA_TYPE_OPTIONS.AUDIO ? (
    <Flex
      css={{
        height: DIALOG_BODY_HEIGHT,
        margin: -DIALOG_PADDING,
        padding: DIALOG_PADDING,
        position: 'relative' as const
      }}
    >
      {renderback('upload', 'Back to upload', 'dark')}
      <WavePreview
        onSubmit={(blob: Blob) => {
          setView('preview')
          setFiles([
            ...files.slice(0, uploadIndex),
            new File([ blob ], 'recording', { type: blob.type }),
            ...files.slice(uploadIndex + 1)
          ])
        }}
      />
    </Flex>
  ) : (
    <Flex
      css={{
        height: DIALOG_BODY_HEIGHT,
        margin: -DIALOG_PADDING,
        padding: DIALOG_PADDING,
        position: 'relative' as const
      }}
    >
      {renderback('upload', 'Back to upload', 'dark')}
      <VideoPreview
        options={{
          height: DIALOG_BODY_HEIGHT,
          width: DIALOG_WIDTH,
          controls: true,
          bigPlayButton: false,
          plugins: {
            record: {
              image: fileTypeCategory === MEDIA_TYPE_OPTIONS.IMAGE,
              audio: fileTypeCategory === MEDIA_TYPE_OPTIONS.VIDEO,
              video: fileTypeCategory === MEDIA_TYPE_OPTIONS.VIDEO,
              maxLength: 60,
              imageOutputType: 'blob',
              debug: process.env.NODE_ENV !== 'production'
            }
          }
        }}
        onSubmit={(blob: Blob) => {
          setView('preview')
          setFiles([
            ...files.slice(0, uploadIndex),
            new File([ blob ], fileTypeCategory === MEDIA_TYPE_OPTIONS.IMAGE ? 'image' : 'video', { type: blob.type }),
            ...files.slice(uploadIndex + 1)
          ])
        }}
        style={{
          position: 'absolute',
          inset: 0,
          height: '100%',
          width: '100%'
        }}
      />
    </Flex>
  )

  const handleImportFromUrl = (link: string) => {
    setFileRejectionError(null)

    return fetch(link).then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.blob()
    }).then((blob) => {
      setFiles([ new File([ blob ], link.split('/').pop() || '') ])
    }).catch(() => {
      setFileRejectionError('Invalid URL')
    })
  }

  const linkView = (
    <StyledFileInput
      justifyContent="center"
      direction="column"
      gap={10}
      variant="bare"
      onPaste={(event: any) => {
        handleImportFromUrl(event.clipboardData?.getData('text') || '')
      }}
    >
      <div style={{ position: 'absolute', left: 0, top: 0 }}>
        {renderback('upload', 'Back', 'dark')}
      </div>
      <FieldLabel>Paste URL here</FieldLabel>
      <Flex gap={10} alignSelf="stretch">
        <TextInput
          autoFocus
          placeholder="https://example.com/file.csv"
          input={{
            onChange: (event: any) => {
              handleImportFromUrl(event.target.value)
            },
            // @ts-ignore
            value: files[0]?.url
          }}
          meta={{
            // @ts-ignore
            touched: !!files[0]?.url,
            error: fileRejectionError
          }}
          onPaste={(e: any) => e.stopPropagation()}
        />
        <Button
          label="Import"
          // @ts-ignore
          disabled={!files[0]}
          mode="subtle"
          onClick={() => setView('preview')}
        />
      </Flex>
    </StyledFileInput>
  )

  const renderView = () => {
    switch (view) {
      case 'upload':
        return uploadView
      case 'list':
        return listView
      case 'record':
        return recordView
      case 'preview':
        return preview
      case 'paste':
        return linkView
    }

    return null
  }

  return (
    <>
      <DialogHeader onCloseClick={onClose} title={title} />
      <DialogBody>
        {renderView()}
      </DialogBody>
      <Divider />
      <DialogFooter css={{ backgroundColor: 'light100', paddingY: 18 }}>
        {view === 'preview' && (
          <Flex gap={20} grow={1} alignItems="center" css={{ width: 0 }}>
            {isArray && (
              <>
                <Flex shrink={0} gap={4} alignItems="center">
                  <IconButton
                    disabled={previewIndex === 0}
                    onClick={() => setPreviewIndex(previewIndex - 1)}
                    size={16}
                    description="Previous"
                    variant="dark"
                    name="arrow-left"
                  />
                  <Divider orientation="vertical" css={{ alignSelf: 'stretch', height: 'auto' }} />
                  <IconButton
                    disabled={previewIndex === files.length - 1}
                    onClick={() => setPreviewIndex(previewIndex + 1)}
                    size={16}
                    description="Next"
                    variant="dark"
                    name="arrow-right"
                  />
                  <Text fontSize={12} css={{ marginLeft: 6 }}><Text as="span" fontWeight="bold">{previewIndex + 1}</Text> / {files.length}</Text>
                </Flex>
                <Divider orientation="vertical" css={{ alignSelf: 'stretch', height: 'auto' }} />
              </>
            )}
            <Text fontSize={12} fontWeight="bold" truncate>
              {files[previewIndex]?.name}
            </Text>
          </Flex>
        )}

        {view === 'preview' && (
          <Button
            mode="subtle"
            label="Replace"
            onClick={() => {
              setView('upload')
              setUploadIndex(previewIndex)
            }}
          />
        )}
        <Button
          disabled={files.length === 0 || ![ 'preview', 'list' ].includes(view)}
          label="Submit"
          onClick={() => onSubmit(files).then(onClose)}
        />
      </DialogFooter>
    </>
  )
}

const FileUploadDialog = ({ isOpen, onClose, title, ...props }: FileUploadDialogProps) => {
  const [ key, setKey ] = useState(0)
  return (
    <Dialog
      isOpen={isOpen}
      contentLabel={title}
      onRequestClose={onClose}
      onAfterClose={() => setKey(key + 1)}
      onAfterOpen={() => setKey(key + 1)}
      style={{ content: { width: DIALOG_WIDTH } }}
    >
      <FileUploadDialogWrapped
        key={key}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        {...props}
      />
    </Dialog>
  )
}

export default FileUploadDialog

export type { FileUploadDialogView }
