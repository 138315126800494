import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import useClientQuery from 'hooks/useClientQuery'
import useDashboard from 'hooks/useDashboard'
import withOnMount from 'hoc/withOnMount'
import { APPS_MENU_ELEMENT_ID, getDefaultIcon } from 'lib/generateDashboard'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { findActiveMenuElement } from 'components/layouts/InternalLayout'
import { PreferencesQuery, PREFERENCES_QUERY } from 'client/state/preferences'
import { SIDEBAR_PRIMARY_BACKGROUND, SIDEBAR_PRIMARY_BORDER_COLOR } from 'components/sidebar/constants'
import { TourGuideContext } from 'components/providers/TourProvider'
import { Views } from 'components/dashboardEditor/constants'
import type useLongPress from 'hooks/useLongPress'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { PopoverToggleProps } from 'components/popover'

const SIDEBAR_ELEMENT_BORDER_LEFT_WIDTH = 2
const SIDEBAR_ELEMENT_HORIZONTAL_PADDING = 25
const SIDEBAR_ELEMENT_VERTICAL_PADDING = 20

const highlightedStyle = {
  backgroundColor: rgba(colorVars.secondary400rgb, 0.3),
  cursor: 'pointer',
  opacity: 1
}

const SidebarItem = styled(Flex, {
  ...mixins.transition('simple'),

  borderLeftColor: 'transparent',
  borderLeftStyle: 'solid',
  borderLeftWidth: SIDEBAR_ELEMENT_BORDER_LEFT_WIDTH,
  opacity: 0.5,
  paddingY: SIDEBAR_ELEMENT_VERTICAL_PADDING,
  paddingLeft: SIDEBAR_ELEMENT_HORIZONTAL_PADDING - SIDEBAR_ELEMENT_BORDER_LEFT_WIDTH,
  paddingRight: SIDEBAR_ELEMENT_HORIZONTAL_PADDING,

  '&:hover': highlightedStyle,

  '& [data-icon]': {
    color: 'light100'
  },

  variants: {
    isDragging: {
      true: {
        opacity: 1,
        background: SIDEBAR_PRIMARY_BACKGROUND
      }
    },
    isHighlighted: {
      true: highlightedStyle
    }
  }
})

const SidebarItemName = styled(Text, {
  ...mixins.transition('fastOut'),

  color: 'light100',
  margin: 0
})

const SidebarSeparatorRuler = styled(Divider, {
  '&::before': {
    opacity: 0.1
  },

  variants: {
    isDragging: {
      true: {
        '&::before': {
          backgroundColor: 'dark100',
          opacity: 0.5
        }
      }
    }
  }
})

const SidebarHeader = styled(Text, {
  ...mixins.transition('fastOut'),

  alignItems: 'center',
  color: 'white',
  display: 'flex',
  paddingY: SIDEBAR_ELEMENT_VERTICAL_PADDING,
  paddingX: SIDEBAR_ELEMENT_HORIZONTAL_PADDING,

  variants: {
    isDragging: {
      true: {
        backgroundColor: 'light100',
        color: 'dark600'
      }
    }
  }
})

const classes = {
  sidebarItem_active: css({
    backgroundColor: rgba(colorVars.secondary400rgb, 0.3),
    borderLeftColor: 'light100',
    opacity: 1,

    '& [data-icon]': {
      ...mixins.dropShadow('icon', colorVars.secondary300rgb)
    },

    '& $name': {
      fontWeight: 'bold'
    }
  })
}

type SidebarElementProps = {
  isDragging?: boolean,
  menuElement: ComputedMenuElement,
  onMenuElementClick?: (menuElement: ComputedMenuElement, e: React.MouseEvent<HTMLElement>) => void,
  popoverToggleProps?: PopoverToggleProps,
  longPressProps?: ReturnType<typeof useLongPress>
}

const IconWithOnMount = withOnMount(Icon)

const SidebarPrimaryElement = ({
  isDragging = false,
  menuElement,
  onMenuElementClick,
  popoverToggleProps,
  longPressProps
}: SidebarElementProps) => {
  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  const location = useLocation()

  const { parentIdToMenuElementsMap } = useContext(InternalContext)!

  const { showNextStep } = useContext(TourGuideContext)!
  const {
    data: { preferences: { isSidebarMinimized } }
  } = useClientQuery<PreferencesQuery>(PREFERENCES_QUERY)

  const { dashboardEditorState } = useDashboard()
  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT

  const linkProps = useMemo(() => {
    const isUrl = menuElement.target === 'URL'
    const isSubmenu = menuElement.target === 'SUBMENU'
    const hasActiveSubMenu = findActiveMenuElement(
      parentIdToMenuElementsMap[menuElement.id] || [], location.pathname
    )
    // eslint-disable-next-line no-nested-ternary
    return (isUrl
      ? { href: menuElement.url }
      : isSubmenu && hasActiveSubMenu
        ? { className: classes.sidebarItem_active }
        : { activeClassName: classes.sidebarItem_active, to: menuElement.fullPath! }
    )
  }, [ menuElement, location.pathname, parentIdToMenuElementsMap ])

  if (menuElement.kind === 'ITEM') {
    return (
      <SidebarItem
        alignItems="center"
        as={isInspecting ? 'div' : BaseLink}
        isDragging={isDragging}
        isHighlighted={isActive}
        gap={14}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => onMenuElementClick?.(menuElement, e)}
        {...contextMenuProps}
        {...(isInspecting ? {} : linkProps)}
        {...longPressProps}
      >
        <IconWithOnMount
          {...((isSidebarMinimized && menuElement.id === APPS_MENU_ELEMENT_ID) && { className: 'tg--apps-menu', onMount: showNextStep })}
          name={menuElement.renderedIcon || getDefaultIcon(menuElement) || 'app-custom'}
          data-icon
          size={20}
        />
        <SidebarItemName
          as="span"
          fontSize={14}
          fontWeight="regular"
          data-collapsible
          truncate
        >
          {menuElement.renderedName}
        </SidebarItemName>
      </SidebarItem>
    )
  }

  if (menuElement.kind === 'SEPARATOR') {
    if (menuElement.separatorStyle === 'RULER') {
      return (
        <SidebarSeparatorRuler
          onClick={isInspecting
            ? (e: React.MouseEvent<HTMLDivElement>) => onMenuElementClick?.(menuElement, e)
            : undefined}
          color={SIDEBAR_PRIMARY_BORDER_COLOR}
          spacing={SIDEBAR_ELEMENT_VERTICAL_PADDING}
          isDragging={isDragging}
          {...contextMenuProps}
          {...longPressProps}
        />
      )
    }

    return (
      <Divider
        onClick={isInspecting
          ? (e: React.MouseEvent<HTMLDivElement>) => onMenuElementClick?.(menuElement, e)
          : undefined}
        variant="whitespace"
        spacing={14}
        {...contextMenuProps}
        {...longPressProps}
      />
    )
  }

  if (menuElement.kind === 'GROUP') {
    return (
      <SidebarHeader
        onClick={isInspecting
          ? (e: React.MouseEvent<HTMLDivElement>) => onMenuElementClick?.(menuElement, e)
          : undefined}
        data-collapsible
        fontSize={12}
        textTransform="uppercase"
        isDragging={isDragging}
        {...contextMenuProps}
        {...longPressProps}
      >
        {menuElement.name}
      </SidebarHeader>
    )
  }

  return null
}

export default SidebarPrimaryElement

export {
  SIDEBAR_ELEMENT_HORIZONTAL_PADDING,
  SIDEBAR_ELEMENT_VERTICAL_PADDING
}
