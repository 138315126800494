import React, { useCallback } from 'react'
import { string, StringSchema } from 'yup'

import FormField from 'components/form/FormField'
import isNullOrUndefined from 'lib/isNullOrUndefined'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { TextInputProps } from 'components/inputs/TextInput'

type TextFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'text'>

const getTextFieldSchema = (settings: TextFieldProps['settings'] = {}, isNullable: boolean, value: any) => {
  let schema: StringSchema<string | null | undefined> = string().nullable(isNullable)
  if (settings.checkRequired && !settings.hasFallbackLocale) schema = schema.required()
  if (settings.checkLength) {
    if (!settings.checkRequired && isNullOrUndefined(value)) {
      return schema
    }

    if (settings.lengthMinimum) schema = schema.min(settings.lengthMinimum)
    if (settings.lengthMaximum) schema = schema.max(settings.lengthMaximum)
    if (settings.lengthEqualTo) schema = schema.length(settings.lengthEqualTo)
  }

  if (settings.checkUniqueness) {
    if (settings.existingValues?.length) {
      schema = schema.notOneOf(settings.existingValues)
    }
  }

  return schema
}

const noop = () => { }
const TextField = ({
  name,
  settings,
  shouldValidate,
  validate: _validate = noop,
  isNullable = false,
  ...others
}: TextFieldProps) => {
  const validate = useCallback(
    (value: string, values: any) => _validate(value, values)
    || getTextFieldSchema(settings, isNullable, value)
      .validate(value)
      .then(noop)
      .catch((e) => e.message),
    [ settings, isNullable, _validate ]
  )

  return (
    <FormField
      name={name}
      component={TextInput}
      parse={(x) => {
        if (!x && isNullable) return null

        return x
      }}
      type="text"
      {...(settings?.multiline && {
        component: TextAreaInput,
        rows: settings.multilineVisibleRows,
        autogrow: settings.multilineAutogrow
      })}
      {...({
        checkRequired: settings?.checkRequired,
        placeholder: settings?.placeholder,
        helpText: settings?.helpText
      })}
      {...(shouldValidate && { validate })}
      {...{ disableEmojis: settings?.disableEmojis }}
      {...others}
    />
  )
}

export type { TextFieldProps }
export { getTextFieldSchema }
export default TextField
