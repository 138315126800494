import React from 'react'

import GenericParamFields from './GenericParamFields'
import RelativeParamFields from './RelativeParamFields'
import { RESERVED_ATTRIBUTES } from 'models/Attribute'
import type { Locale } from 'hooks/useActiveLocales'
import type { Operation, Parameter } from 'generated/schema'

type ParameterFieldsProps = {
  isDefaultOpened?: boolean,
  currentLocale?: Locale,
  defaultLocale?: Locale,
  isUpdating: boolean,
  operation?: Operation,
  parameters: Parameter[],
  prefix: string,
  resourceId?: string,
  targetEnvironmentId?: string
}

const ParameterFields = ({
  parameters,
  ...rest
}: ParameterFieldsProps) => {
  const { isUpdating, operation } = rest

  const isSystemAttribute = (p: Parameter) => {
    if (!operation?.resource) return false

    return RESERVED_ATTRIBUTES.includes(p.attribute?.identifier!)
    || [
      operation.resource.creationTimestampAttributeId,
      operation.resource.updationTimestampAttributeId,
      operation.resource.deletionTimestampAttributeId
    ].includes(p.attributeId)
  }

  const genericParams = operation?.resource
    ? parameters.filter((p) => !isSystemAttribute(p) || isUpdating)
    : parameters

  const relativeParams = (parameters.filter((p) => !!p.relationshipId) || []) as Parameter[]

  return (
    <>
      <GenericParamFields {...rest} parameters={genericParams} />
      <RelativeParamFields {...rest} parameters={relativeParams} />
    </>
  )
}

export default ParameterFields
