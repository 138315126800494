import dayjs from 'dayjs'
import get from 'lodash/get'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'

import AccountModel from 'models/Account'
import AccountNameRenderer from 'components/renderers/AccountNameRenderer'
import DataTableBlock from 'components/blocks/DataTableBlock'
import DateRenderer from 'components/renderers/DateRenderer'
import RecordVersionView from './RecordVersionView'
import usePager from 'hooks/usePager'
import { ContentVersion, ContentVersionStatus } from 'models/Content'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { generateStatusRenderer } from 'components/renderers/StatusRenderer'
import { Resource, useRecordVersionsListQuery } from 'generated/schema'
import type { ChipProps } from 'components/chip/Chip'
import type { Column, RendererOptions } from 'components/dataTable/types'
import type { ViewProps } from 'components/views'
import { useViewDispatch } from 'hooks/useViewContext'

dayjs.extend(relativeTime)

type Params = {
  recordId: string,
  latestId: string,
  publishedId: string,
  isPublishingEnabled: boolean,
  resource: Resource
}

const dateRenderer = (props: RendererOptions) => (
  <DateRenderer {...props} full />
)

const versionStatusVariants: Record<string, ChipProps['variant']> = {
  // publishing workflow enabled
  [ContentVersionStatus.PUBLISHED]: 'positive',
  [ContentVersionStatus.CURRENT_DRAFT]: 'neutral',
  [ContentVersionStatus.OLDER_DRAFT]: 'light',
  // publishing workflow disabled
  [ContentVersionStatus.CURRENT]: 'neutral',
  [ContentVersionStatus.OLDER]: 'light'
}

const StatusRenderer = generateStatusRenderer<ContentVersion>({
  statusVariants: versionStatusVariants
})

function RecordVersionsListView({
  closeView,
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { openView } = useViewDispatch()
  const { resource, recordId, latestId, publishedId, isPublishingEnabled } = params

  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()

  const {
    data,
    loading,
    error
  } = useRecordVersionsListQuery({
    variables: {
      filter: { recordId: { eq: recordId } },
      order: [ { createdAt: 'desc' } ],
      limit: pageSize,
      page
    }
  })

  const {
    recordVersionsList = [],
    recordVersionsAggregate
  } = data || {}

  const versionsCount = recordVersionsAggregate?.count || 0

  const getStatusOf = (versionId: string) => {
    if (isPublishingEnabled) {
      let status = ContentVersionStatus.OLDER_DRAFT

      if (latestId === versionId) {
        status = ContentVersionStatus.CURRENT_DRAFT
      }
      if (publishedId === versionId) {
        status = ContentVersionStatus.PUBLISHED
      }

      return status
    }

    let status = ContentVersionStatus.OLDER
    if (latestId === versionId) {
      status = ContentVersionStatus.CURRENT
    }

    return status
  }

  const startVersionIndex = versionsCount - ((page - 1) * pageSize)
  const versions = recordVersionsList.map((version, index) => ({
    ...version,
    index,
    name: `Version ${startVersionIndex - index}`,
    status: getStatusOf(version.id)
  }))

  const onVersionClick = (recordVersion: ContentVersion) => {
    openView({
      component: RecordVersionView,
      style: 'MODAL',
      title: 'Version Details',
      params: {
        resource,
        isPublishingEnabled,
        currentVersion: recordVersion,
        versions: versions as any[]
      }
    })
  }

  const columns: Column[] = [
    { dataKey: 'name', title: 'Name', sortable: true, style: { width: '25%' }, renderer: generateLinkRenderer({ onClick: onVersionClick }) },
    { dataKey: 'createdBy', title: 'Created By', sortable: true, style: { width: 300 }, renderer: AccountNameRenderer, renderCellTitle: ({ rowData, dataKey }) => AccountModel.getFullName(get(rowData, dataKey)) },
    { dataKey: 'createdAt', title: 'Creation Time', style: { flexGrow: 1 }, renderer: dateRenderer },
    { dataKey: 'status', title: 'Status', sortable: true, style: { flexGrow: 1, justifyContent: 'flex-end' }, renderer: StatusRenderer }
  ]

  return (
    <View
      contentLabel="Version History"
      onRequestClose={onRequestClose}
      {...other}
    >
      {({ Body, Header }) => (
        <>
          <Header title="History" onCloseClick={onRequestClose} wide />
          <Body>
            <DataTableBlock
              asFragment
              loading={loading}
              error={error}
              actions={[]}
              columns={columns}
              data={versions}
              selectionMode="none"
              onChangePage={handlePageChange}
              onChangePageSize={handlePageSizeChange}
              page={page}
              pageSize={pageSize}
              paginationMode="finite"
              totalRows={versionsCount}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default RecordVersionsListView
