import omit from 'lodash/omit'
import React, { useMemo } from 'react'

import AddWorkspaceLocaleView from 'components/views/AddWorkspaceLocaleView'
import Button from 'components/buttons/Button'
import DataTableBlock from 'components/blocks/DataTableBlock'
import LocaleFallbackRenderer from 'components/renderers/LocaleFallbackRenderer'
import TitleBlock from 'components/blocks/TitleBlock'
import {
  useRemoveWorkspaceLocaleMutation
} from 'generated/schema'
import useActiveLocales, { Locale } from 'hooks/useActiveLocales'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'

const columns: Column[] = [
  { dataKey: 'name', title: 'Name', style: { width: 400 } },
  { dataKey: 'identifier', title: 'Identifier', style: { width: 200 } },
  { dataKey: 'fallbacks', title: 'Fallback', style: { flexGrow: 1 }, renderer: LocaleFallbackRenderer }
]

function LocalesPage() {
  const confirm = useConfirmation()
  const { openView } = useViewDispatch()

  const { activeLocales, error, loading } = useActiveLocales()

  const [ removeEnvironmentLocale ] = useRemoveWorkspaceLocaleMutation()

  const handleRemoveLocale = useSubmitHandler(removeEnvironmentLocale)

  const openEditLocaleView = (locale: Locale) => {
    openView({
      title: 'Update Locale',
      component: AddWorkspaceLocaleView,
      style: 'PANEL',
      params: {
        activeLocales,
        initialValues: {
          ...omit(locale, 'name')
        }
      }
    })
  }

  const openAddLocaleView = () => {
    openView({
      title: 'Add Locale',
      component: AddWorkspaceLocaleView,
      style: 'PANEL',
      params: {
        activeLocales,
        initialValues: {}
      }
    })
  }

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: openEditLocaleView
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: ({ identifier }: Locale) => confirm({
        action: 'delete',
        recordType: 'locale',
        recordDescription: identifier,
        onConfirmClick: () => handleRemoveLocale({
          identifier
        })
      })
    }
  ]

  const summary = useMemo(() => {
    if (!activeLocales.length) {
      return ''
    }

    const defaultLocale = activeLocales[0].name
    const restLocalesLength = activeLocales.length - 1

    return defaultLocale
      ? `${defaultLocale}${restLocalesLength ? ` + ${restLocalesLength}` : ''}`
      : ''
  }, [ activeLocales ])

  const addComponent = (
    <Button icon="add-thin" size="small" onClick={openAddLocaleView} />
  )

  return (
    <>
      <TitleBlock heading="Locales" />

      <DataTableBlock
        columns={columns}
        data={activeLocales}
        loading={loading}
        error={error}
        secondaryElements={[ addComponent ]}
        selectionMode="none"
        actions={actions as any}
      />

    </>
  )
}

export default LocalesPage
