import arrayMutators from 'final-form-arrays'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import kebabCase from 'lodash/kebabCase'
import pick from 'lodash/pick'
import React, { useContext, useEffect, useState, useMemo } from 'react'
import size from 'lodash/size'
import sum from 'lodash/sum'
import { Field, Form, FormSpy } from 'react-final-form'
import { useHistory, useLocation } from 'react-router-dom'
import type { FormApi } from 'final-form'
import type { FormProps, FormRenderProps } from 'react-final-form'

import * as mixins from 'styles/mixins'
import AddFieldView from 'components/views/AddFieldView'
import AppError from 'components/errors/AppError'
import Block from 'components/blocks/Block'
import Button, { ButtonProps } from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import client from 'client'
import ContentModel from 'models/Content'
import ContentVersionView from 'components/views/cms/ContentVersionView'
import Divider from 'components/divider/Divider'
import DrawerBlock from 'components/blocks/DrawerBlock'
import FieldModel from 'models/Field'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import generatePosition from 'lib/generatePosition'
import GenericContentEditor from 'components/contentEditors/generic'
import IconButton from 'components/buttons/IconButton'
import PageLoader from 'components/loaders/PageLoader'
import Tab from 'components/tabs/Tab'
import Tabs, { useTabs } from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TitleBlock from 'components/blocks/TitleBlock'
import useActiveLocales from 'hooks/useActiveLocales'
import useConfirmation from 'hooks/useConfirmation'
import useJumpToView from 'hooks/useJumpToView'
import useSubmitHandler from 'hooks/useSubmitHandler'
import useVersionPublishing, { PublishingAction } from 'hooks/useVersionPublishing'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { ContentDocument, ContentsListDocument, FieldsListQuery, useContentQuery, useCreateContentMutation, useDestroyContentMutation, useFieldsListQuery, useUpdateContentMutation } from 'generated/schema'
import { createFocusOnErrors } from 'lib/formDecorators/focusOnErrors'
import { FIELDS_LIST_LIMIT } from 'components/views/cms/FieldsList'
import { GetMediaFieldStateContext } from 'components/contexts/MediaFieldContext'
import { styled } from 'styles/stitches'
import { useViewDispatch } from 'hooks/useViewContext'
import type { CustomContent, ContentTypeFragmentFragment as ContentTypeFragment, CreateContentInput, Field as FieldType, UpdateContentInput } from 'generated/schema'
import type { Locale } from 'hooks/useActiveLocales'

type AddContentViewProps = {
  contentId?: CustomContent['id'],
  contentType?: ContentTypeFragment,
  hideIdentifierField?: boolean,
  onAddContent?: (contentId: string) => void,
  onEditContent?: (contentId: string) => void,
  prefetchedContent?: Partial<CustomContent>,
  sidePaneMode?: boolean
}

type FormValues = CreateContentInput | UpdateContentInput

type TitleProps = {
  contentType?: ContentTypeFragment,
  fieldsList: FieldsListQuery['fieldsList'],
  primaryElements?: React.ReactNode,
  secondaryElements: React.ReactNode,
  isUpdating: boolean,
  activeLocale: ReturnType<typeof useActiveLocales>['activeLocales'][number],
  defaultLocale: ReturnType<typeof useActiveLocales>['defaultLocale']
}

type LocationState = { content?: CustomContent } | undefined

const TAB_PADDING = 35
const TAB_NOTIFICATION_SIZE = 8

const StyledTabsWrapper = styled(Flex, {
  border: '1 solid light700',
  borderWidth: 0.5
})

const StyledTab = styled(Tab, {
  padding: TAB_PADDING,

  variants: {
    background: {
      light: {
        backgroundColor: 'light100'
      },
      none: {}
    }
  }
})

const StyledTabNotification = styled(Flex, {
  ...mixins.size(TAB_NOTIFICATION_SIZE),

  background: 'negative700',
  borderRadius: '50%',
  color: 'light700',
  position: 'absolute',
  right: 0,
  top: 8
})

const focusOnErrors = createFocusOnErrors<FormValues>()

const CustomTitleBlock = ({
  contentType,
  fieldsList,
  activeLocale,
  defaultLocale,
  isUpdating,
  primaryElements,
  secondaryElements
}: TitleProps) => {
  const { pathname, search } = useLocation()

  const renderTitleBlock = (title: string) => (
    <TitleBlock
      heading={title}
      secondaryElements={secondaryElements}
      hideLastCrumb={false}
      primaryElements={primaryElements}
      appendStickyCrumb={{
        name: title,
        isActive: true,
        fullPath: pathname + search
      }}
    />
  )

  return (
    <FormValuesField fieldNames={[ 'data' ]}>
      {(values) => {
        const title = ContentModel.resolveTitle(fieldsList, values.data, contentType, {
          activeLocale: activeLocale?.identifier,
          defaultLocale: defaultLocale?.identifier,
          fallbackTitle: `${isUpdating ? 'Update' : 'New'} Content`
        })

        return renderTitleBlock(title || 'Untitled')
      }}
    </FormValuesField>
  )
}

type LocaleTabNotificationBadgeProps = {
  index: number,
  fieldsList: FieldsListQuery['fieldsList'],
  locale: Locale['identifier']
}

const LocaleTabNotificationBadge = ({
  index, fieldsList, locale
}: LocaleTabNotificationBadgeProps) => {
  const { activeIndex } = useTabs()

  if (index === activeIndex) {
    return null
  }

  return (
    <FormSpy subscription={{ errors: true, submitFailed: true }}>
      {({ errors, submitFailed }) => {
        const notificationCount = sum(fieldsList.map(
          (field) => size(errors?.data?.[field.id]?.[locale]) || 0
        ))

        return submitFailed && Boolean(notificationCount) && (
          <StyledTabNotification justifyContent="center" alignItems="center" />
        )
      }}
    </FormSpy>
  )
}

const SubmitButton = ({ submitting, isPublishingEnabled, ...rest }: Pick<FormRenderProps, 'submitting'> & { isPublishingEnabled: boolean } & ButtonProps) => {
  const uploadingMediaFields = useContext(GetMediaFieldStateContext)

  return (
    <Button
      key="submit-button"
      disabled={submitting || uploadingMediaFields.length}
      label={isPublishingEnabled ? 'Save Draft' : 'Save'}
      type="submit"
      mode={isPublishingEnabled ? 'subtle' : 'distinct'}
      {...rest}
    />
  )
}

const PublishButton = ({ submitting, isPublished, ...rest }: Pick<FormRenderProps, 'submitting'> & { isPublished: boolean } & ButtonProps) => {
  const uploadingMediaFields = useContext(GetMediaFieldStateContext)

  return (
    <Button
      disabled={submitting || uploadingMediaFields.length}
      label={isPublished ? 'Unpublish' : 'Publish'}
      variant={isPublished ? 'outline' : 'filled'}
      {...rest}
    />
  )
}

const IdentifierBlock = ({ hasError }: { hasError: boolean }) => {
  const [ isOpen, setIsOpen ] = useState(hasError)

  useEffect(() => {
    if (hasError) setIsOpen(true)
  }, [ hasError ])

  return (
    <DrawerBlock
      isOpen={isOpen}
      setIsOpened={setIsOpen}
      as={Flex}
      icon="settings"
      title="Advanced Options"
    >
      {() => (
        <Flex direction="column" gap={14}>
          <Field
            autoFocus={hasError}
            name="identifier"
            label="Identifier"
            size="small"
            alwaysDirty
            component={TextInput}
            type="text"
          />
        </Flex>
      )}
    </DrawerBlock>
  )
}

const AddContentView = ({
  contentId,
  contentType,
  hideIdentifierField = false,
  onAddContent,
  onEditContent,
  prefetchedContent,
  sidePaneMode = false
}: AddContentViewProps) => {
  const isUpdating = Boolean(contentId)
  const { pathname, state } = useLocation<LocationState>()
  const { replace } = useHistory()
  const { push } = useHistory()
  const { openView } = useViewDispatch()
  const { isPublishingEnabled = false } = contentType || {}

  const skipContentQuery = !isUpdating || Boolean(prefetchedContent)

  const confirm = useConfirmation({ style: 'DIALOG' })
  const [ localeIndex, setLocaleIndex ] = React.useState(0)

  const contentTypeViewMenuElement = useJumpToView({
    type: 'app',
    id: 'cms',
    componentPath: 'cms/ContentTypesView'
  })

  const {
    data,
    loading: contentLoading,
    error: contentError
  } = useContentQuery({ variables: { id: contentId }, skip: skipContentQuery })

  const content = data?.content || prefetchedContent || state?.content

  const shouldShowPublishing = isPublishingEnabled && isUpdating
  const isPublished = isPublishingEnabled
    && content?.publishedContentVersionId === content?.currentContentVersionId

  const {
    activeLocales,
    defaultLocale,
    loading: localesLoading
  } = useActiveLocales()

  const {
    data: { fieldsList = [] } = {},
    loading: fieldsLoading,
    error: fieldsError
  } = useFieldsListQuery({
    variables: {
      filter: {
        contentTypeId: { eq: contentType?.id }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: FIELDS_LIST_LIMIT
    },
    skip: !contentType?.id
  })
  const isFieldsListEmpty = fieldsList.length < 1

  // const hasLocalizedFields = fieldsList.filter((field) => field.isTranslatable)

  const [ createContent ] = useCreateContentMutation({
    onCompleted: (response) => {
      onAddContent?.(response.createContent.id)

      const newContent = response.createContent
      client.writeQuery({
        query: ContentDocument,
        data: {
          content: newContent
        },
        variables: {
          id: newContent.id
        }
      })

      if (!sidePaneMode) {
        push({
          search: `id=${response.createContent.id}`
        })
      }
    }
  })

  const [ handlePublishing ] = useVersionPublishing()

  const onPublishingClick = () => (
    handlePublishing({
      action: isPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      contentId,
      contentVersionId: content?.currentContentVersionId
    })
  )

  const createContentSubmitHandler = useSubmitHandler(createContent, {
    update: {
      strategy: 'APPEND',
      query: ContentsListDocument,
      dataKey: 'contentsList',
      mutation: 'createContent',
      queryVariables: {
        filter: {
          contentTypeId: { eq: contentType?.id }
        }
      }
    },
    successAlert: {
      message: 'Content created.'
    }
  })

  const [ updateContent ] = useUpdateContentMutation({
    onCompleted: (response) => {
      onEditContent?.(response.updateContent.id)
    }
  })

  const updateContentSubmitHandler = useSubmitHandler(updateContent, {
    successAlert: {
      message: 'Content updated.'
    }
  })

  const handleContentFormSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    const isDataEmpty = isEmpty(values.data)
    const valuesWithData = isDataEmpty ? { ...values, data: {} } : values

    if ('id' in values) {
      return updateContentSubmitHandler(
        valuesWithData as UpdateContentInput, form as FormApi<UpdateContentInput>
      )
    }
    return createContentSubmitHandler(valuesWithData as CreateContentInput)
  }

  const [ destroyContent ] = useDestroyContentMutation()
  const handleDestroyContent = useSubmitHandler(destroyContent, {
    update: {
      strategy: 'REMOVE',
      query: ContentsListDocument,
      dataKey: 'contentsList',
      mutation: 'destroyContent',
      queryVariables: {
        filter: {
          contentTypeId: { eq: contentType?.id }
        }
      }
    }
  })

  const getFormInitialValues = (isResetting = false) => (
    content && !isResetting
      ? content
      : { contentTypeId: contentType?.id, data: {} }
  ) as FormValues

  const initialValues = useMemo(getFormInitialValues, [ content, contentType ])

  if ((contentLoading && !content) || (localesLoading && !activeLocales)) {
    return <PageLoader loading />
  }

  if (contentError) {
    return <AppError defaultCode={404} defaultMessage="Content not found." />
  }

  const renderSecondaryElements = ({
    submitting, handleSubmit, form
  }: FormRenderProps<FormValues>) => {
    const actionItems = []

    const duplicateButton = (
      <IconButton
        key="duplicate"
        name="duplicate"
        description="Duplicate"
        variant="dark"
        size={24}
        onClick={() => {
          push({ search: 'action=create', state: { content: pick(content, 'contentTypeId', 'data') } })
        }}
      />

    )

    const historyButton = (
      <IconButton
        key="revision-history"
        name="clock-outline"
        description="Revision History"
        variant="dark"
        size={24}
        onClick={() => {
          if (content?.id) {
            openView({
              title: 'Version History',
              component: ContentVersionView,
              style: 'MODAL',
              params: {
                isPublishingEnabled,
                contentId: content.id,
                fieldsList: fieldsList as FieldType[]
              }
            })
          }
        }}
      />
    )

    const deleteButton = (
      <IconButton
        key="delete-content"
        name="trash"
        description="Delete Content"
        variant="dark"
        size={24}
        onClick={() => {
          confirm({
            action: 'delete',
            onConfirmClick: async () => {
              const result = await handleDestroyContent({ id: contentId })
              if (contentType?.kind === 'SINGLETON') {
                form.setConfig('keepDirtyOnReinitialize', false)
                form.reset(getFormInitialValues(true))
                form.setConfig('keepDirtyOnReinitialize', true)
              }
              // redirect to content list page
              // replace because back button would result in 404 after delete
              replace(pathname)

              return result
            },
            recordType: 'Content'
          })
        }}
      />
    )

    if (isUpdating) {
      if (contentType?.kind !== 'SINGLETON') {
        actionItems.push(duplicateButton)
      }
      actionItems.push(deleteButton)
      actionItems.push(historyButton)
    }

    const secondaryElements = (
      <Flex gap={16}>
        {contentType?.id && (
          <>
            {actionItems}
            {Boolean(actionItems.length) && <Divider orientation="vertical" />}
            <SubmitButton
              submitting={submitting}
              onClick={handleSubmit}
              isPublishingEnabled={isPublishingEnabled}
              size="small"
            />
            {shouldShowPublishing && (
              <PublishButton
                isPublished={isPublished}
                submitting={submitting}
                onClick={onPublishingClick}
                size="small"
              />
            )}
          </>
        )}
      </Flex>
    )

    const primaryElements = shouldShowPublishing ? (
      <Chip
        label={isPublished ? 'published' : 'current draft'}
        variant={isPublished ? 'positive' : 'dark100'}
        css={{ flexShrink: 0 }}
      />
    ) : null

    return { secondaryElements, primaryElements }
  }

  const openFieldsListView = () => {
    push({
      pathname: contentTypeViewMenuElement?.fullPath || '',
      state: {
        selectedContentType: contentType
      }
    })
    openView({
      title: 'New Field',
      component: AddFieldView,
      style: 'PANEL',
      params: {
        initialValues: {
          contentTypeId: contentType?.id,
          position: generatePosition()
        }
      }
    })
  }

  const EmptyElement = (
    <Flex alignItems="center" direction="column" gap={20}>
      <Text fontWeight="bold">Start by adding a new field</Text>
      <Button label="Add Field" mode="distinct" variant="simple" size="small" onClick={openFieldsListView} />
    </Flex>
  )

  const {
    actualTitleField, potentialTitleField
  } = FieldModel.getTitleField(fieldsList, contentType)

  const titleField = actualTitleField || potentialTitleField

  return (
    <Form
      onSubmit={handleContentFormSubmit}
      mutators={{
        ...arrayMutators
      }}
      decorators={[ focusOnErrors ]}
      initialValues={initialValues || {}}
      initialValuesEqual={isEqual}
      subscription={{
        submitting: true,
        submitErrors: true
      }}
      render={(formRenderProps) => {
        const { handleSubmit, submitting, submitErrors } = formRenderProps

        const {
          secondaryElements,
          primaryElements
        } = renderSecondaryElements(formRenderProps)

        const contentForm = (currentLocale?: Locale) => (
          <PageLoader
            data={fieldsList}
            loading={fieldsLoading}
            error={fieldsError}
            empty={{ element: EmptyElement }}
          >
            {sidePaneMode && (
              <Flex justifyContent="flex-end">
                <SubmitButton
                  submitting={submitting}
                  handleSubmit={handleSubmit}
                  isPublishingEnabled={isPublishingEnabled}
                />
              </Flex>
            )}
            <Flex as="form" onSubmit={handleSubmit} gap={20} direction="column">
              <GenericContentEditor
                fieldsList={fieldsList}
                currentLocale={currentLocale}
                defaultLocale={defaultLocale}
              />

              <input style={{ display: 'none' }} type="submit" />
            </Flex>
          </PageLoader>
        )

        return (
          <>
            {!sidePaneMode && !!fieldsList.length && (
              <CustomTitleBlock
                activeLocale={activeLocales[localeIndex]}
                defaultLocale={defaultLocale}
                isUpdating={isUpdating}
                primaryElements={primaryElements}
                secondaryElements={secondaryElements}
                fieldsList={fieldsList}
                contentType={contentType}
              />
            )}
            <Block direction="column" width={{ md: '100%' }}>
              <Field name="id" component="input" type="hidden" />
              <Field name="identifier" component="input" type="hidden" />
              {titleField && defaultLocale && !('id' in initialValues) && (
                <WhenFieldChanges
                  field={`data.${titleField.id}.${defaultLocale.identifier}`}
                  set="identifier"
                  to={(value: string) => kebabCase(value)}
                />
              )}
              <Flex direction="column" gap={14}>
                {activeLocales.length && !isFieldsListEmpty ? (
                  <StyledTabsWrapper direction="column">
                    <Tabs
                      appendNode={(
                        <>
                          {content?.updatedAt && (
                            <Text
                              color="dark500"
                              css={{ paddingRight: 16 }}
                              fontSize={14}
                              title={dayjs(content.updatedAt).format('MMM D, YYYY h:mm A')}
                            >
                              Last modified {dayjs(content.updatedAt).fromNow()}
                            </Text>
                          )}
                        </>
                      )}
                      onChange={setLocaleIndex}
                    >
                      {activeLocales.map((locale, index) => (
                        <StyledTab
                          index={index}
                          key={locale.name}
                          label={locale.name}
                          appendHeaderNode={(
                            <LocaleTabNotificationBadge
                              index={index}
                              fieldsList={fieldsList}
                              locale={locale.identifier}
                            />
                          )}
                          background="light"
                          alwaysMounted
                        >
                          {contentForm(locale)}
                        </StyledTab>
                      ))}
                    </Tabs>
                  </StyledTabsWrapper>
                ) : contentForm(defaultLocale)}
                {!hideIdentifierField && !isFieldsListEmpty && (
                  <IdentifierBlock hasError={Boolean(submitErrors?.identifier)} />
                )}
              </Flex>
            </Block>
          </>
        )
      }}
    />
  )
}

export { SubmitButton, PublishButton }
export default AddContentView
