import React, { Suspense, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import componentLoader from 'lib/componentLoader'
import Masonry from 'components/layout/Masonry'
import NotFoundPage from 'components/pages/NotFoundPage'
import PageLoader from 'components/loaders/PageLoader'
import useDashboard from 'hooks/useDashboard'
import useViewUrn from 'hooks/useViewUrn'
import { DashboardView } from 'components/pages/DashboardPage'
import { safeParseLiquid } from 'lib/templater'
import type { ViewProps } from '.'

type Params = {
  viewUrn: string,
  params?: Record<string, any>
}

function GenericView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const [ title, setTitle ] = useState('')
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)

  const { data: activeView, loading, error } = useViewUrn({ urn: params.viewUrn })

  const activeApp = activeView?.app
  const activeAppId = activeApp?.id || activeView?.appId
  const activeResourceId = activeView?.resourceId
  const activeViewId = activeView?.id
  const activeBlocks = activeView?.blocks || []
  const resource = activeView?.resource
  const componentPath = activeView?.componentPath
  const isCustomResourceView = activeResourceId && activeBlocks.length

  const ViewComponent = useMemo(() => (
    componentPath && !isCustomResourceView
      ? React.lazy(() => componentLoader(`views/${componentPath}`))
      : () => null
  ), [ componentPath, isCustomResourceView ])

  const { blockPropertiesState } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)
  const parsedTitle = safeParseLiquid(title, blockProperties)

  if (!activeView) {
    return <PageLoader loading={loading} error={error} />
  }

  if (!componentPath
    && !activeView
    && !loading
  ) {
    return <NotFoundPage fullscreen={false} />
  }

  if (componentPath && ((activeAppId && activeApp) || (activeResourceId && resource))) {
    return (
      <Suspense fallback={<PageLoader loading />}>
        <ViewComponent
          key={activeViewId}
          params={params.params}
          onRequestClose={onRequestClose}
          viewStyleComponent={View}
          {...other}
        />
      </Suspense>
    )
  }

  return (
    <View contentLabel={parsedTitle} onRequestClose={onRequestClose} {...other}>
      {({ Body, Header, Footer }) => (
        <>
          <Header
            title={parsedTitle}
            onCloseClick={onRequestClose}
          />
          <Body>
            <Masonry>
              <DashboardView
                activeUrn={params.viewUrn}
                activeView={activeView}
                activeAppId={activeAppId}
                setTitle={setTitle}
                footerEl={footerEl}
              />
            </Masonry>
          </Body>
          <Footer>
            <Footer.Right ref={setFooterEl} />
          </Footer>
        </>
      )}
    </View>
  )
}

export default GenericView
