import arrayMutators from 'final-form-arrays'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import capitalize from 'lodash/capitalize'
import merge from 'lodash/merge'
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { Form, FormProps } from 'react-final-form'
import type { FormApi } from 'final-form'

import AttributeModel from 'models/Attribute'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import componentLoader from 'lib/componentLoader'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import FormValuesField from 'components/form/FormValuesField'
import Grid from 'components/layout/Grid'
import Loader from 'components/loaders/Loader'
import MediaCard from 'components/mediaCard/MediaCard'
import PageLoader from 'components/loaders/PageLoader'
import pascalCase from 'lib/pascalCase'
import Text from 'components/typography/Text'
import ToggleInput from 'components/inputs/ToggleInput'
import SelectInput from 'components/inputs/SelectInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { AttributesListDocument, AttributesListQueryVariables, CreateAttributeInput, FieldType, Installation, UpdateAttributeInput, useCreateAttributeMutation, useFieldTypesListQuery, useUpdateAttributeMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { defaultFormValues } from './AddFieldView'
import type { FieldIdentifier } from 'models/Field'
import type { ViewProps, ViewStyleComponentRenderProps } from 'components/views'

type FormValues = CreateAttributeInput | UpdateAttributeInput

type Params = {
  initialValues: FormValues,
  fieldType?: FieldType,
  installationId?: Installation['id'],
  queryVariables: AttributesListQueryVariables
}

const groupTypes = [ 'BASIC', 'ADVANCED', 'OTHER' ]

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

const getFieldViewName = (fieldType?: FieldType) => {
  if (!fieldType) return ''

  const { identifier } = fieldType
  // text-field -> TextFieldView
  return `${pascalCase(identifier)}View`
}

function AddAttributeView({
  closeView,
  onRequestClose,
  params: { initialValues, installationId, queryVariables },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const [ selectedFieldType, setSelectedFieldType ] = useState<FieldType>()
  const [ step, setStep ] = useState(isUpdating ? 2 : 1)

  const title = (() => {
    const operation = isUpdating ? 'Edit' : 'New'
    const selectedFieldTypeName = step === 2 && selectedFieldType?.name

    return `${operation} ${selectedFieldTypeName || ''} Attribute`
  })()

  const {
    data: { fieldTypes = [] } = {},
    loading,
    error
  } = useFieldTypesListQuery()

  const [ createAttribute ] = useCreateAttributeMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: AttributesListDocument, variables: queryVariables } ]
  })

  const [ updateAttribute ] = useUpdateAttributeMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: AttributesListDocument, variables: queryVariables } ]
  })

  const handleCreateAttribute = useSubmitHandler(createAttribute, {
    successAlert: { message: 'Attribute Created.' }
  })

  const handleUpdateAttribute = useSubmitHandler(updateAttribute, {
    successAlert: { message: 'Attribute Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if (isUpdating) {
      return handleUpdateAttribute(
        values as UpdateAttributeInput,
        form as FormApi<UpdateAttributeInput>
      )
    }
    return handleCreateAttribute(values as CreateAttributeInput)
  }

  const fieldViewName = getFieldViewName(selectedFieldType)

  const fieldViewFileName = pascalCase(fieldViewName)
  const Base = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Base }))
  ), [ fieldViewFileName ])

  const Preview = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Preview }))
  ), [ fieldViewFileName ])

  const Configurations = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Configurations }))
  ), [ fieldViewFileName ])

  const Validations = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Validations }))
  ), [ fieldViewFileName ])

  useEffect(() => {
    if (isUpdating && !selectedFieldType) {
      setSelectedFieldType(fieldTypes.find(
        (ft) => ft.identifier === initialValues.fieldType
      ))
    }
  }, [ isUpdating, fieldTypes, initialValues, selectedFieldType ])

  const renderMediaCard = (field: FieldType, groupIndex: number, typesIndex: number) => (
    <MediaCard
      autoFocus={
        (groupIndex === 0 && typesIndex === 0) || selectedFieldType?.identifier === field.identifier
      }
      key={field.identifier}
      media={field.icon}
      onClick={() => {
        setSelectedFieldType(field)
        setStep(step + 1)
      }}
      title={field.name}
      width="full"
    />
  )

  const groupedFieldTypes = useMemo(() => {
    const fieldsWithoutRelationship = fieldTypes?.filter((field) => field.identifier !== 'relationship-field')
    const orderedFields = orderBy(fieldsWithoutRelationship, 'position')
    return groupBy(orderedFields, 'category')
  }, [ fieldTypes ])

  const fieldTypeView = ({ Body, Header, SubHeader, Footer }: ViewStyleComponentRenderProps) => (
    <>
      <Header title={title} onCloseClick={onRequestClose} />
      <SubHeader>
        <Text fontWeight="bold">Step 1: Select a template</Text>
      </SubHeader>
      <Body>
        <PageLoader
          data={fieldTypes}
          error={error}
          loading={loading}
        >
          <Flex direction="column" gap={14}>
            {groupTypes.map((TYPE, groupIndex) => {
              if (!groupedFieldTypes?.[TYPE]?.length) return null

              return (
                <div key={TYPE}>
                  <Text fontWeight="bold">{capitalize(TYPE)} fields</Text>
                  <Divider spacing={10} variant="whitespace" />
                  <Grid columnGap={20} rowGap={20} columns={2}>
                    {groupedFieldTypes[TYPE].map(
                      (field, typesIndex) => renderMediaCard(field, groupIndex, typesIndex)
                    )}
                  </Grid>
                  <Divider spacing={10} variant="whitespace" />
                </div>
              )
            })}
          </Flex>
        </PageLoader>
      </Body>
      <Footer>
        <Flex alignItems="center" grow={1} justifyContent="space-between">
          <Chip label="Step 1" icon="arrow-right" iconPlacement="right" variant="light" />
          <Flex gap={14}>
            <Button disabled icon="arrow-left" size="small" />
            <Button
              disabled={!selectedFieldType}
              icon="arrow-right"
              onClick={() => setStep(step + 1)}
              size="small"
            />
          </Flex>
        </Flex>
      </Footer>

    </>
  )

  const attributeDetailsView = ({
    Body, Header, SubHeader, Footer
  }: ViewStyleComponentRenderProps) => (
    <>
      <Header title={title} onCloseClick={onRequestClose} />
      {!isUpdating && (
        <SubHeader>
          <Text fontWeight="bold">Step 2: Enter Details</Text>
        </SubHeader>
      )}
      <Form
        decorators={[
          setIdentifier
        ]}
        mutators={{
          ...arrayMutators
        }}
        initialValues={
          merge(
            {},
            selectedFieldType?.identifier
              ? defaultFormValues[selectedFieldType.identifier as FieldIdentifier]
              : {},
            {
              defaultValue: {
                value: null,
                type: 'fixed'
              },
              isFilterable: AttributeModel
                .isFilterableField(selectedFieldType?.identifier as FieldIdentifier),
              isNullable: AttributeModel
                .isNullableField(selectedFieldType?.identifier as FieldIdentifier),
              isOrderable: AttributeModel
                .isOrderableField(selectedFieldType?.identifier as FieldIdentifier),
              isTranslatable: false,
              ...initialValues,
              fieldType: selectedFieldType?.identifier
            }
          )
        }
        keepDirtyOnReinitialize
        subscription={{
          submitting: true,
          pristine: true
        }}
        onSubmit={handleSubmit}
        validate={(values) => AttributeModel.validate(values, [ 'identifier', 'name' ])}
        render={({ handleSubmit, submitting, pristine, form }) => (
          <>
            <Body>
              <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
                <Flex direction="column" gap={20}>
                  <Flex direction="column" gap={14}>
                    <Suspense fallback={<Loader loading />}>
                      {isUpdating && (
                        <FormField
                          alwaysDirty
                          component={SelectInput}
                          name="fieldType"
                          label="Attribute Type"
                          size="small"
                          options={fieldTypes}
                          labelKey="name"
                          valueKey="identifier"
                          onChange={(option: FieldType) => {
                            setSelectedFieldType(option)
                            form.change('fieldType', option.identifier)
                          }}
                        />
                      )}
                      <Grid columns={2} columnGap={8} rowGap={8}>
                        <Base fieldPrefix="fieldTypeSettings" />
                      </Grid>
                      <Configurations {...{
                        selectedFieldType,
                        fieldPrefix: 'settings.',
                        translatableFieldName: 'isTranslatable',
                        repeatableFieldName: 'isArray',
                        installationId
                      }}
                      />
                      <FormValuesField fieldNames={[ 'fieldType' ]}>
                        {({ fieldType }) => (
                          <>
                            {AttributeModel.isFilterableField(fieldType) && (
                              <FormField
                                component={ToggleInput}
                                name="isFilterable"
                                label="Enable Filtering"
                                helpText="Allow the resource to filter by this attribute"
                                labelPosition="left"
                                size="small"
                                type="checkbox"
                              />
                            )}
                            {AttributeModel.isNullableField(fieldType) && (
                              <FormField
                                component={ToggleInput}
                                name="isNullable"
                                label="Allow Nullable"
                                helpText="Allow the attribute to assume a NULL value if no value is provided"
                                labelPosition="left"
                                size="small"
                                type="checkbox"
                              />
                            )}
                            {AttributeModel.isOrderableField(fieldType) && (
                            <FormField
                              component={ToggleInput}
                              name="isOrderable"
                              label="Allow Ordering"
                              helpText="Allow the resource to order by this attribute"
                              labelPosition="left"
                              size="small"
                              type="checkbox"
                            />
                            )}
                          </>
                        )}
                      </FormValuesField>
                      <Preview fieldPrefix="" repeatableFieldName="isArray" />

                      <Validations fieldPrefix="settings." />
                      <FormField type="hidden" name="settings" alwaysDirty />
                      <input type="submit" style={{ display: 'none' }} />
                    </Suspense>
                  </Flex>
                </Flex>
              </Flex>
            </Body>
            <Footer>
              <Flex alignItems="center" direction={isUpdating ? 'row-reverse' : 'row'} grow={1} justifyContent="space-between">
                {!isUpdating && <Chip label="Step 2" icon="arrow-right" iconPlacement="right" variant="light" />}
                <Flex gap={14}>
                  {!isUpdating && <Button icon="arrow-left" onClick={() => setStep(step - 1)} size="small" />}
                  <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} size="small" />
                </Flex>
              </Flex>
            </Footer>
          </>
        )}
      />
    </>
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {step === 2 ? attributeDetailsView : fieldTypeView}
    </View>
  )
}

export default AddAttributeView
