import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { DragOverlay, useDraggable } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import InternalContext from 'components/contexts/InternalContext'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useHotKey from 'hooks/useHotKey'
import useRedirectToMenuElement from 'hooks/useRedirectToMenuElement'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { APP_LIST_LIMIT } from 'models/App'
import { DashboardsListDocument, GenerateMenuElementsInput, useAppListQuery, useGenerateMenuElementsMutation } from 'generated/schema'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type InitialValues = GenerateMenuElementsInput

type Params = ViewParams[Views.ADD_MENU_PROJECT]

const AddMenuProjectView = ({ onClose }: ActiveViewProps) => {
  const [ search, setSearch ] = useState('')

  return (
    <>
      <DashboardEditorHeader
        subtitle="Add Project Menu"
        heading="Add Menu"
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Text color="dark900" fontSize={12}>
          Provide easy access to Projects and its Resources by
          {' '}<b>dragging</b> a Project Menu onto the <b>sidebar</b> or <b>topbar</b>.
        </Text>
        <ProjectsGrid search={search} />
      </DashboardEditorBody>
    </>
  )
}

const DraggableCard = ({ project, handleAddToDashboard, params, isActive, isOverlay }: any) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: project.id,
    data: {
      appId: project.id
    }
  })

  const onAdd = () => handleAddToDashboard({
    appId: project.id,
    ...params
  })

  return (
    <MediaCard
      compact
      onClick={onAdd}
      key={project.id}
      css={{
        opacity: isActive ? 0.5 : 1,
        border: isActive && !isOverlay ? '1px dashed gray' : '1px solid transparent'
      }}
      media={(
        <AppIcon
          isProject
          technology={project.technology}
          isCustomApp={!!project.workspaceId}
          identifier={project.identifier as AppIconIdentifier}
          icon={project.icon}
          size={32}
        />
      )}
      title={project.name}
      height={64}
      width="full"
      isDraggable
      actions={[
        {
          description: '',
          icon: 'drag-block',
          onClick: onAdd,
          isIconAlwaysVisible: true
        }
      ]}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    />
  )
}

const ProjectsGrid = ({ search = '' }: { search: string }) => {
  const {
    data: { appsList } = {},
    error: appsListError,
    loading: appsListLoading
  } = useAppListQuery({
    variables: {
      filter: {
        or: [
          { kind: { eq: 'PROJECT' } }
        ]
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const { draggedAppState, draggingOverMenuIdState } = useDashboard()

  const [ draggedApp, setDraggedApp ] = useRecoilState(draggedAppState)
  const setDraggingOverMenuId = useSetRecoilState(draggingOverMenuIdState)

  useEffect(() => () => {
    setDraggedApp(null)
  }, [ setDraggedApp ])

  useHotKey({
    Escape: () => {
      setDraggedApp(null)
      setDraggingOverMenuId(null)
    }
  })

  const filteredProjects = appsList?.filter(
    (project) => project.name.toLowerCase().includes(search.toLowerCase())
  ) || []

  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { currentDashboard } = useContext(InternalContext)!

  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params

  const { placement = 'SIDE', parentId } = initialValues as InitialValues

  const redirect = useRedirectToMenuElement()

  const dashboardId = currentDashboard?.id

  const [ addToDashboard ] = useGenerateMenuElementsMutation({
    onCompleted: (data) => {
      const menuElement = data.generateMenuElements[0]
      if (menuElement) redirect(menuElement)
      else stepBackDashboardEditor()
    },
    refetchQueries: [ { query: DashboardsListDocument } ]
  })

  const handleAddToDashboard = useSubmitHandler(addToDashboard, {
    successAlert: { message: 'Successfully added to dashboard.' }
  })

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Your Projects
      </Text>
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={appsList}
        loading={appsListLoading}
        error={appsListError}
      >
        <Flex direction="column" gap={2}>
          {filteredProjects.map((project) => {
            const isActive = draggedApp?.id === project.id

            return (
              <DraggableCard
                key={project.id}
                project={project}
                params={{
                  dashboardId,
                  parentId,
                  ...(parentId ? {} : { placement })
                }}
                isActive={isActive}
                handleAddToDashboard={handleAddToDashboard}
              />
            )
          })}
        </Flex>
      </DashboardEditorLoader>
      {createPortal(
        <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
          {draggedApp?.id ? (
            <DraggableCard
              project={filteredProjects.find((p) => p.id === draggedApp.id)}
              isActive
              isOverlay
            />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </Flex>
  )
}

export default AddMenuProjectView
