import React from 'react'
import type { ReactNode } from 'react'

import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'

type FieldLabelProps = {
  checkRequired?: boolean,
  children?: ReactNode,
  isTranslatable?: boolean,
  htmlFor?: string
}

const StyledFieldLabel = styled(Flex, {
  [`${Icon}`]: {
    color: 'dark100'
  }
})

function FieldLabel(
  { children, isTranslatable = false, checkRequired = false, ...others }: FieldLabelProps
) {
  if (!children) return null

  return (
    <StyledFieldLabel gap={8} alignItems="center" justifyContent="space-between">
      <Text
        color="dark500"
        fontSize={10}
        textTransform="uppercase"
        fontWeight="bold"
        {...others}
      >
        {children}
        {checkRequired && <span> *</span>}
      </Text>
      {isTranslatable && (
        <Flex alignItems="center" alignSelf="stretch" gap={12}>
          <Divider variant="ruler" orientation="vertical" color="light700" />
          <Icon size={12} name="translate" />
        </Flex>
      )}
    </StyledFieldLabel>
  )
}

export default FieldLabel
